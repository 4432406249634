import React, { useState } from 'react';
import ReactDOM from 'react-dom'

class W3wConfigIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {introduction: props.introduction};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({introduction: event.target.value});
  }

  getCharacterCreditCount() {
    var messageLength = this.state.introduction.length;
    var size = 160;
    messageLength += (this.state.introduction.match(/\u00A3/g)||[]).length;
    return messageLength;
  }

  render() {
    return (
      <div className="accordion" id="accordionIntroduction">
        <div className="card">
          <div className="card-header" id="accordionIntroductionHeading">
            <h2 className="mb-0">
              <button className="btn btn-link btn-block text-left" type="button" data-bs-toggle="collapse" data-bs-target="#accordionIntroductionCollapse" aria-expanded="true" aria-controls="accordionIntroductionCollapse">
                SMS Message to Send
              </button>
            </h2>
          </div>

          <div id="accordionIntroductionCollapse" className="collapse" aria-labelledby="accordionIntroductionHeading" data-bs-parent="#accordionIntroduction">
            <div className="card-body">
              <div className="text-center mx-3">
                <p>Note: 1 credit covers the first 160 characters, and each additional 154 characters are another credit. This message should briefly introduce your company and the request. You can give further information in the request introduction.</p>
                <textarea rows={3} className="mb-2 form-control" name="w3w_config[introduction]" id="w3w_config_introduction" value={this.state.introduction} onChange={this.handleChange}/>
                <small className="form-text">{this.getCharacterCreditCount()} characters</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("w3w_config_introduction")
  if (element != null) {
    const data = JSON.parse(element.getAttribute('data'));
    ReactDOM.render(<W3wConfigIntro {...data} />, element);
  }
})
